import tour1 from "./images/tour-1.jpeg";
export const pageLinks = [
  { id: 1, href: "#home", text: "home" },
  { id: 2, href: "#about", text: "about" },
  { id: 3, href: "#services", text: "services" },
  { id: 4, href: "#tours", text: "tours" },
];

export const socialLinks = [
  { id: 1, href: "www.twitter.com", icon: "fab fa-facebook" },
  { id: 2, href: "www.twitter.com", icon: "fab fa-twitter" },
  { id: 3, href: "www.twitter.com", icon: "fab fa-squarespace" },
];

export const serviceData = [
  {
    id: 1,
    icon: "fas fa-wallet fa-fw",
    title: "saving money",
    text: "loremsfdfsdfdsf",
  },
  {
    id: 2,
    icon: "fas fa-tree fa-fw",
    title: "ending hiking",
    text: "loremsfdfsdfdsf",
  },
  {
    id: 3,
    icon: "fas fa-socks fa-fw",
    title: "Amazing Comfort",
    text: "loremsfdfsdfdsf",
  },
];

export const tourData = [
  {
    id: 1,
    cost: 2100,
    duration: 6,
    location: "China",
    date: "August 26th,2020",
    title: "Tibet Adventure",
    img: tour1,
    info: "lorenmsdfsdfs",
  },
  {
    id: 2,
    cost: 2100,
    duration: 6,
    location: "China",
    date: "August 26th,2020",
    title: "Tibet Adventure",
    img: tour1,
    info: "lorenmsdfsdfs",
  },
  {
    id: 3,
    cost: 2100,
    duration: 6,
    location: "China",
    date: "August 26th,2020",
    title: "Tibet Adventure",
    img: tour1,
    info: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur magnam fuga magni corrupti sit perspiciatis necessitatibus, obcaecati aliquam reiciendis, ullam tempore cum laudantium ipsa molestias illum quos libero dignissimos nam.",
  },
];
