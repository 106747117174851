import Title from "./Title";
import { serviceData } from "../data";

const Services = () => {
  return (
    <section className="section services" id="services">
      <Title title="OUr" subTitle="services"></Title>
      {serviceData.map((service) => {
        return (
          <article className="service">
            <span className="service-icon">
              <i className={service.icon}></i>
            </span>
            <div className="service-info">
              <h4 className="service-title">{service.title}</h4>
              <p className="service-text">{service.text}</p>
            </div>
          </article>
        );
      })}
    </section>
  );
};
export default Services;
